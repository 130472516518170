<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="aligncenter size-full wp-image-23016" src="@/assets/news/8/1.jpg" alt="图片" width="500" height="334"></p>
        <p>2021年8月19日至8月27日期间，以下新规适用于所有飞往希腊的国际航班：</p>
        <p>自8月19日至8月27日6时，第三国（非欧盟国家）公民仍禁止进入希腊。</p>
        <p>欧盟和申根地区的永久居民以及35个国家（其中包括中国）的公民不受此规定的限制。</p>
        <p>自8月19日起，所有乘坐国际航班到达希腊的乘客都必须在https://travel.gov.gr网站上填写《旅客定位表》，在抵达希腊之前填写旅客本人在希腊的联系方式。有效的《旅客定位表》和二维码是进入希腊的必要先决条件。</p>
        <p>所有搭乘国际航班的乘客必须满足以下条件之一：</p>
        <p>（1）在入境前14天接种完新冠疫苗，并出示由公共机构根据有关法律签发的接种证明，语言可以为英语、法语、德语、意大利语、西班牙语或俄语。接种证明必须写清接种者姓名（跟护照上一致）、疫苗种类、接种剂数和接种日期。</p>
        <p>（2）出示入境前72小时内的阴性核酸检测证明或入境前48小时内的阴性快速检测证明，语言可以为英语、法语、德语、意大利语、西班牙语或俄语。该证明上的持有人姓名需与本人护照上一致。</p>
        <p>此外，从各国（其中包括中国）飞往希腊的国际航班的乘客在抵达希腊时必须在机场接受强制性新冠核酸或快速检测，并留在机场的指定地点直至检测结果公布。持有疫苗接种证明或既往新冠感染康复证明的乘客可免除强制检测。</p>
        <p>（3）或者，出示由公共机构根据有关法律签发的，既往感染之日起2至9个月内有效的既往新冠感染康复证明或阳性核酸检测或快速检测证明，由出发国或转机国认可的实验室证明，或者由该国相关国家主管当局认同的公共或私人实验室证明。该证明语言可为英语、法语、德语、意大利语、西班牙语或俄语。该证明上的持有人姓名需与本人护照上一致。</p>
        <p>上述条件也适用于12岁以上的儿童。</p>
        <p>若检测结果为阳性，则旅客需接受为期10天的居家或集中隔离。若旅客在抵达希腊14天前已完成新冠疫苗接种且检测结果为阳性，需接受为期7天的临时隔离。</p>
        <p>值得注意的是，由希腊驻上海总领事馆签发的申根签证仅能入境希腊，不能进入其他申根地区的欧盟成员国。在另行通知前，请勿通过申根地区的欧盟成员国转机进入希腊。</p>
        <p>强烈建议前往希腊的中国公民搭乘直航。若通过第三国（非欧盟）国家入境希腊，中国公民必须承担确保该第三国（非欧盟）国家允许他们转机的责任。</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "8",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:8,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>